<template>
  <div class="aboutUs">
    <TopNavBar2 />
    <Carousel :carousels="carousels">
      <el-button slot="button" class="btn" @click="jumpClick"
        >去联系 <i class="el-icon-right"></i
      ></el-button>
    </Carousel>
    <div class="box">
      <div class="business_box">
        <Title title="主营业务" />
        <div class="business">
          <div v-for="(item, index) in business" :key="index" class="item">
            {{ item.title }}
          </div>
          <img src="@/assets/images/aboutUs/business_img.png" alt="" />
        </div>
      </div>
    </div>
    <div class="mission_box">
      <Title title="公司使命" />
      <img src="@/assets/images/aboutUs/mission2.png" alt="" />
      <div class="bottom_box">
        <div v-for="(item, index) in missions" :key="index">
          <p>{{ item.title }}</p>
          <p>{{ item.title2 }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="box">
      <div class="course_box">
        <Title title="发展历程" />
        <div class="courses">
          <div
            v-for="(item, index) in courses"
            :key="index"
            class="item"
            @click="courseActive = index"
            :class="courseActive === index ? 'courseActive' : ''"
          >
            <p>{{ item }}</p>
            <img
              v-if="courseActive === index"
              src="@/assets/images/aboutUs/course4.svg"
              alt=""
            />
            <img v-else src="@/assets/images/aboutUs/course3.svg" alt="" />
            <div>
              <div></div>
            </div>
          </div>
          <div class="bottom">
            <img src="@/assets/images/aboutUs/course2.svg" alt="" />
          </div>
        </div>
        <div class="information_box">
          <div v-for="(item, index) in courseInfos" :key="index">
            <div class="item">
              <div class="date">{{ item.date }}</div>
              <div class="title">
                <div>
                  <div></div>
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </div>
            <div v-if="index < courseInfos.length - 1" class="bottom"></div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="contact_box" id="anchor">
      <Title title="联系我们" />
      <div class="address" id="container"></div>
      <div class="contact">
        <div class="item">
          <img src="@/assets/images/aboutUs/icon1.png" alt="" />
          <h3>公司地址</h3>
          <p>浙江省台州市椒江区第一时间生活广场2楼255室</p>
        </div>
        <div class="item">
          <img src="@/assets/images/aboutUs/icon2.png" alt="" />
          <h3>客服热线</h3>
          <p>0576-81810718</p>
          <p>周一至周五</p>
          <p>9:00-15:00</p>
        </div>
      </div>
    </div>
    <Footer2 />
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "AboutUs",
  data() {
    return {
      business: [
        {
          title: "智慧文博场馆",
        },
        {
          title: "智慧政务",
        },
        {
          title: "AR/VR数字展厅",
        },
        {
          title: "智慧医疗",
        },
        {
          title: "数智集团",
        },
      ],
      missions: [
        {
          title: "数字化",
          title2: "核心力量",
        },
        {
          title: "智能化",
          title2: "时代扬帆",
        },
        {
          title: "信息化",
          title2: "技术起航",
        },
      ],
      courses: [2022, 2021, 2020, 2019],
      courseActive: 0,
      courseInfos: [
        {
          date: "10月",
          title: "浙江起航软件科技公司获得高新技术企业证书",
        },
        {
          date: "06月",
          title: "浙江起航软件科技公司获得高新技术企业证书",
        },
        {
          date: "05月",
          title: "公司搬迁第一时间生活广场2楼255室",
        },
        {
          date: "02月",
          title: "浙江起航软件科技公司成立",
        },
      ],
      map: null,
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/8.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.initAMap(121.415772, 28.653729);
  },
  methods: {
    initAMap(lng, lat) {
      var that = this;
      AMapLoader.load({
        key: "9d5d867e7fe51867e37324dd95002bb2", //设置您的key
        version: "2.0",
        plugins: [
          "AMap.ToolBar",
          "AMap.Driving",
          "AMap.Marker",
          "AMap.PlaceSearch ",
          "AMap.AutoComplete",
          "AMap.Geolocation",
          "AMap.InfoWindow",
        ],
        AMapUI: {
          version: "1.1",
          plugins: [],
        },
        Loca: {
          version: "2.0",
        },
      })
        .then((AMap) => {
          that.map = null;
          if (!this.map) {
            that.map = new AMap.Map("container", {
              viewMode: "3D",
              zoom: 14, //初始化地图级别
              // zooms: [2, 22],
              center: [lng, lat], //初始化地图中心点位置
              resizeEnable: true,
            });
            that.marker = new AMap.Marker({
              content:
                '<img style="height:60px; width: 60px;" src="' +
                require("@/assets/images/aboutUs/location_icon.png") +
                '" />',
              position: new AMap.LngLat(lng, lat),
              offset: new AMap.Pixel(-30, -30),
            });
            that.marker.setMap(that.map);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    jumpClick() {
      document.querySelector("#anchor").scrollIntoView(true);
    },
  },
};
</script>

<style scoped lang="scss">
.aboutUs {
  ::v-deep .btn {
    position: absolute;
    left: 20%;
    top: 70%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 75px;
    height: 26px;
    padding: 0;
    background-color: #00d577;
    font-size: 12px;
    color: #fff;
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .business_box {
    margin-top: 20px;
    width: 325px;
    .business {
      position: relative;
      height: 185px;
      img {
        position: absolute;
        width: 151px;
        height: 143px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
      .item {
        position: absolute;
        text-align: left;
        line-height: 26px;
        font-family: "AlibabaPuHuiTi-Light";
        font-weight: 200;
        color: #2e8eff;
        font-weight: 200;
        font-size: 15px;
        letter-spacing: 1px;
      }
      .item:nth-child(1) {
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
      .item:nth-child(2) {
        top: 76px;
        right: 20px;
      }
      .item:nth-child(3) {
        bottom: -28px;
        right: 5px;
      }
      .item:nth-child(4) {
        bottom: -28px;
        left: 50px;
      }
      .item:nth-child(5) {
        top: 76px;
        left: 20px;
      }
    }
  }
  .mission_box {
    margin-top: 60px;
    padding-top: 20px;
    height: 240px;
    background-color: #f6fafd;
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      margin-top: 10px;
      width: 345px;
      height: 129px;
    }
    .bottom_box {
      display: flex;
      justify-content: space-between;
      width: 345px;
      > div {
        flex: 1;
        font-weight: 200;
        font-size: 15px;
        letter-spacing: 2px;
        text-align: center;
        color: #333;
        line-height: 22px;
      }
    }
  }
  .course_box {
    margin-top: 30px;
    width: 360px;
    .courses {
      position: relative;
      margin-top: 20px;
      height: 78px;
      display: flex;
      justify-content: space-around;
      .item {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        margin-bottom: 1px;
        img {
          width: 45px;
          height: 49px;
        }
        p {
          position: absolute;
          font-weight: 400;
          font-size: 15px;
          color: #ffffff;
          bottom: 32px;
        }
        > div {
          min-width: 11px;
          min-height: 11px;
          border-radius: 50%;
          background-color: #dddddd50;
          display: flex;
          justify-content: center;
          align-items: center;
          > div {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #dddddd;
          }
        }
      }
      .courseActive {
        > img {
          width: 61px;
          height: 67px;
        }
        p {
          font-size: 18px;
          bottom: 38px;
        }
        > div {
          background-color: #006eff50;
          > div {
            background-color: #006eff;
          }
        }
      }
      .bottom {
        position: absolute;
        bottom: 0;
        width: 360px;
        height: 11px;
        background-image: url(~@/assets/images/aboutUs/course1.svg);
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
        img {
          position: absolute;
          right: -6px;
          top: 1px;
          transform: rotate(90deg);
        }
      }
    }
    .information_box {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      display: flex;
      > div {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .date {
            min-width: 50px;
            height: 20px;
            line-height: 20px;
            border: 1px solid #1f74ff;
            border-radius: 20px;
            font-weight: 200;
            font-size: 12px;
            color: #1f74ff;
            font-family: "AlibabaPuHuiTi-Light";
          }
          .title {
            width: 100%;
            > div {
              font-weight: 200;
              font-size: 15px;
              line-height: 22px;
              text-align: left;
              display: flex;
              align-items: center;
              > div {
                width: 30px;
                height: 1px;
                background-color: rgb(31, 116, 255);
                margin: 0 6px;
              }
            }
          }
        }
        .bottom {
          width: 1px;
          height: 60px;
          background-color: rgb(31, 116, 255);
          margin: 5px 0 5px 25px;
        }
      }
    }
  }
  .contact_box {
    margin: 50px 0 30px;
    ::v-deep .address {
      height: 250px;
      margin: 20px auto 0;
      .amap-logo,
      .amap-copyright {
        display: none !important;
      }
    }
    .contact {
      margin-top: 25px;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      .item {
        position: relative;
        padding: 30px 15px 15px;
        width: 135px;
        height: 105px;
        box-shadow: 0px 0px 5px #86baea40;
        img {
          position: absolute;
          width: 30px;
          height: 30px;
          top: -15px;
          left: 50%;
          transform: translateX(-50%);
        }
        h3 {
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          line-height: 30px;
        }
        p {
          font-weight: 200;
          font-size: 14px;
          color: #000000;
          line-height: 25px;
          font-family: "AlibabaPuHuiTi-Light";
        }
      }
      .item + .item {
        margin-left: 15px;
      }
    }
  }
}
</style>
